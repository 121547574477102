@import '../../../../assets/theme/_var.scss';

.ExchangeSection {
    padding: 7rem 0 5rem;

    @media (max-width: 1679px) {
        padding: 3rem 0 1rem;
    }

    @media (max-width: 575px) {
        padding: 2rem 0 0;
    }

    h2 {
        font-weight: 600;
        text-transform: capitalize;
        margin-bottom: 6rem;
        color: $textclrblack;

        @media (max-width: 1679px) {
            margin-bottom: 2.5rem;
        }

        @media (max-width: 575px) {
            margin-bottom: 1.5rem;
        }
    }

    .row {
        margin: 0 -1.5rem;

        >div {
            padding: 0 1.5rem;
            @media (max-width: 767px) {
              margin-bottom: 3rem;
            }
        }
    }
}