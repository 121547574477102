@import '../../assets/theme/_var.scss';

.Homelayout {
    min-height: 100vh;
    background-color: $white;
    background-image: url(../../assets/images/main_bg.jpg);
    background-repeat: no-repeat;
    background-size: 100%;

    @media (max-width: 1679px) {
        background-size: cover;
    }
}