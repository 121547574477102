@import "../../../../assets/theme/_var.scss";

section.GraphSection {
  padding: 7.5rem 0 2rem;
  @media (max-width: 991px) {
    padding: 5rem 0;
  }
  @media (max-width: 767px) {
    padding: 1rem 0;
  }
}

.Graph-wrap {
  height: 90rem;
  padding: 10rem 30rem 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: transparent url(../../../../assets/images/chart.png) repeat 0 0;
  animation: slide 30s linear infinite;

  @media (max-width: 1679px) {
    height: 80rem;
    padding: 10rem 5rem 5rem;
  }
  @media (max-width: 991px) {
    height: 60rem;
  }
  @media (max-width: 767px) {
    height: 50rem;
    background-size: cover;
  }
  @media (max-width: 575px) {
    padding: 1rem;
    height: 30rem;
  }

  img {
    height: 100%;
  }
}

@keyframes slide {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 512px 0;
  }
}
