@import url("https://fonts.googleapis.com/css2?family=Lexend:wght@100;200;300;400;500;600;700;800;900&display=swap"); // lexend font

@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"); // monteserrat font

$baseFont: "Lexend", sans-serif;
$baseFont2: "Montserrat", sans-serif;

$basecolor: linear-gradient(180deg, rgba(255, 255, 255, 0) 74.46%, #FFFFFF 100%);
$basefontcolr: #00002B;
$lightgrey: #E0DFDF;
$textcolor: #2A2A2A;
$white: #fff;
$black: #000;
$textclrblack: #141416;
$textgreyblack: #4F4F4F;
$borderColor4: #D6D6D6;

$gradient: linear-gradient(180deg, #FC9D04 0%, #ED7314 100%);
$gradient2: linear-gradient(98.96deg, #FFCA63 1.74%, #C0558C 97.68%);
$gradient3: linear-gradient(180deg, #F89209 -20.77%, rgba(254, 251, 240, 0) 100%);
$orange: #F89209;