@import "../../../../assets/theme/_var.scss";

.hero-section {
  padding: 13rem 0 2rem;
  @media (max-width: 1679px) {
    padding: 12rem 0 2rem;
  }
  @media (max-width: 1365px) {
    padding: 10rem 0 2rem;
  }
  @media (max-width: 767px) {
    padding: 2rem 0;
  }
  h1 {
    letter-spacing: 2.1px;
    margin-bottom: 1rem;
    // margin-inline: auto;
    // max-width: 70rem;
    @media (max-width: 1365px) {
      margin-bottom: 1.5rem;
    }
    @media (max-width: 575px) {
      margin-bottom: 1rem;
      line-height: 3.2rem;
      font-size: 2.4rem;
    }
    // span {
    //   margin-right: 20px;
    //   display: inline-block;
    // }
  }
  h4 {
    overflow: hidden;
    position: relative;
    font-weight: 500;
    letter-spacing: 0.84px;
    margin-bottom: 4.5rem;
    padding: 1rem 0;
    color: $black;
    @media (max-width: 1365px) {
      margin-bottom: 3rem;
    }
    @media (max-width: 575px) {
      margin-bottom: 2rem;
      font-size: 1.6rem;
    }
    span{
      display: inline-block;
      margin-right: 8px;
    }
  }
  .try_btn{
    padding: 0.5rem 0.5rem 0.5rem 5rem;
    @media (max-width: 767px) {
      padding: 0.5rem 0.5rem 0.5rem 3rem;
    }
  }
  h6 {
    color: $textgreyblack;
    margin-bottom: 1rem;
    font-weight: 500;
  }
  h2{
    font-weight: 600;
    color: $black;
    @media (max-width: 575px) {
     font-size: 2.4rem;
    }
  }

  &__wrap {
    min-height: calc(100vh - 26.5rem);
    padding-bottom: 23.5rem;
    position: relative;
    z-index: 1;
    @media (max-width: 1365px) {
      padding-bottom: 20rem;
    }
    @media (max-width: 991px) {
      padding-bottom: 15rem;
      min-height: fit-content;
    }
    @media (max-width: 767px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-top: 8rem;
    }
    @media (max-width: 575px) {
      justify-content: flex-end;
      max-width: 360px;
      margin: 0 auto;
      padding-top: 2rem;
      padding-bottom: 3rem;
    }

    .icons-wrap {
      position: absolute;

      @media (max-width: 575px) {
        z-index: -1;
        opacity: 0.1;
      }

      svg {
        max-width: 10rem;
        margin: 0 auto;

        @media (max-width: 1679px) {
          max-width: 8rem;
          height: auto;
        }

        @media (max-width: 1365px) {
          max-width: 6rem;
        }

        @media (max-width: 767px) {
          max-width: 4rem;
        }
      }

      // top left & right

      &-one,
      &-seven {
        top: -8.5rem;

        @media (max-width: 767px) {
          top: 0;
        }
      }
      &-one {
        left: 12.5rem;

        @media (max-width: 1365px) {
          left: 10%;
        }
        @media (max-width: 767px) {
          left: 5%;
        }
      }
      &-seven {
        right: 12.5rem;

        @media (max-width: 1365px) {
          right: 10%;
        }
        @media (max-width: 767px) {
          right: 5%;
        }
      }
      // mid left & right
      &-two,
      &-six {
        top: 50%;
        transform: translateY(-13.2rem);
        @media (max-width: 767px) {
          transform: translateY(-10rem);
        }
        @media (max-width: 575px) {
          transform: none;
          top: 10rem;
        }
      }
      &-two {
        left: 10px;
      }
      &-six {
        right: 0;
      }
      // bottom left & right

      &-three,
      &-five {
        bottom: 14.5rem;

        @media (max-width: 575px) {
          top: 20rem;
          bottom: auto;
        }
      }

      &-three {
        left: 29.5rem;
        @media (max-width: 1365px) {
          left: 20%;
        }
        @media (max-width: 767px) {
          left: 10%;
        }
      }
      &-five {
        right: 29.5rem;
        @media (max-width: 1365px) {
          right: 20%;
        }
        @media (max-width: 767px) {
          right: 10%;
        }
      }

      // bottom center

      &-four {
        right: 50%;
        transform: translateX(50%);
        bottom: 2.8rem;

        @media (max-width: 575px) {
          top: 10rem;
          bottom: auto;
        }
      }
    }
  }
}

.animated {
  transition: 200ms linear;
  animation: scroll_down 1s linear infinite alternate;

  @keyframes scroll_down {
    0% {
      transform: translateX(0);
    }
    80% {
      transform: translateY(-0.8rem);
    }
    100% {
      transform: translateY(-0.8rem);
    }
  }
}

// animation delay

.icons-wrap-two {
  .animated {
    animation-delay: 0.2s;
  }
}

.icons-wrap-three {
  .animated {
    animation-delay: 0.9s;
  }
}
.icons-wrap-four {
  .animated {
    animation-delay: 0.5s;
  }
}
.icons-wrap-five {
  .animated {
    animation-delay: 1.2s;
  }
}
.icons-wrap-six {
  .animated {
    animation-delay: 0.7s;
  }
}
.icons-wrap-seven {
  .animated {
    animation-delay: 1.4s;
  }
}
