@import "../../../assets/theme/_var.scss";

.Common_Card {
  border-radius: 1.2rem;
  background: $white;
  // box-shadow: 0px -3px 30px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 4px 20px 0px #0000001A;
  text-align: left;
  padding: 4rem 3.8rem;
  height: 100%;

  @media (max-width: 991px) {
    padding: 2rem 2rem;
  }

  @media (max-width: 575px) {
    padding: 1.5rem;
  }


  h5 {
    color: $textclrblack;
    font-weight: 600;
    margin: 2rem 0 1.5rem;

    @media (max-width: 575px) {
      font-size: 1.2rem;
      margin: 1.2rem 0;
    }
  }

  p {
    font-weight: 400;
    color: $textgreyblack;
  }

  .Cardimg-wrap {
    // min-height: 13rem;
    // display: flex;
    // justify-content: center;
    // align-items: center;

    // @media (max-width: 991px) {
    //   min-height: 10rem;
    // }

    // @media (max-width: 575px) {
    //   min-height: 6rem;
    // }

    img {
      width: 8rem;
      height: 8rem;

      // @media (max-width: 991px) {
      //   max-width: 8rem;
      // }

      @media (max-width: 575px) {
        width: 5rem;
        height: 5rem;
      }

      // &.card_icon {
      //   margin-bottom: -3rem;

      //   @media (max-width: 991px) {
      //     margin-bottom: -1rem;
      //   }
      // }
    }
  }

}