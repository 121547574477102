@import "../../../assets/theme/_var.scss";

.footer {
  &_social {
    padding: 4.4rem 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media (max-width: 991px) {
      padding: 3rem 1.5rem;
    }
    @media (max-width: 575px) {
      padding: 2rem 1.5rem;
    }

    &_logo {
      margin-bottom: 3.6rem;
      @media (max-width: 991px) {
        margin-bottom: 2rem;
      }

      img {
        @media (max-width: 991px) {
          max-width: 10rem;
        }
        @media (max-width: 575px) {
          max-width: 7.5rem;
        }
      }
    }
  }
  &_copyright {
    border-top: 0.1rem solid rgba(99, 103, 112, 0.3);
    padding: 3rem 1.5rem;

    @media (max-width: 767px) {
      padding: 2rem 1rem;
    }

    p {
      color: $textcolor;
      text-align: center;
      font-family: $baseFont2;
      font-size: 1.4rem;
      font-weight: 500;
      line-height: 2.2rem;

      @media (max-width: 767px) {
        font-size: 1.2rem;
        line-height: 1.8rem;
      }
    }
  }
}
