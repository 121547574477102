@import "../../../../assets/theme/_var.scss";

.signup-sec {
  padding: 8rem 0 4rem;

  @media (max-width: 991px) {
    padding: 2.5rem 0;
  }
  &_wrap {
    background-image: url("../../../../assets/images/signup_bg.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: bottom;
    border-radius: 3rem;
    background-color: $white;
    box-shadow: 0px 3px 12px 0px rgba(0, 0, 0, 0.06);
    padding: 5rem;

    @media (max-width: 991px) {
      padding: 2.5rem;
      background-size: cover;
      background-position: 0%;
    }
    @media (max-width: 575px) {
      // padding: 2rem;
    }
    h3 {
      margin-bottom: 4.2rem;

      @media (max-width: 767px) {
        margin-bottom: 2rem;
      }
      @media (max-width: 575px) {
        font-size: 1.6rem;
      }
    }
  }

  &_form {
    max-width: 60.6rem;
    margin: 0 auto;
    padding: 3rem 5.6rem;
    border-radius: 2.8rem;
    border: 0.1rem solid transparent;
    background: linear-gradient(0deg, $white -9.28%, $white 90.1%),
      linear-gradient(
        0deg,
        transparent 0,
        transparent 30%,
        transparent 40%,
        $orange 95%
      );
    background-clip: padding-box, border-box;
    background-origin: padding-box, border-box;

    @media (max-width: 767px) {
      padding: 2rem 3rem;
    }

    @media (max-width: 575px) {
      padding: 2rem;
    }

    &_wrap {
      margin-bottom: 1.8rem;
      @media (max-width: 767px) {
        margin-bottom: 1.2rem;
      }
      label {
        display: block;
        color: $textgreyblack;
        font-size: 1.4rem;
        font-weight: 500;
        letter-spacing: 0.42px;
        text-transform: capitalize;
        margin-bottom: 1rem;
        @media (max-width: 767px) {
          font-size: 1.2rem;
          margin-bottom: 0.75rem;
        }
      }
      input {
        padding: 1.2rem 2.5rem;
        width: 100%;
        color: $black;
        font-size: 1.6rem;
        font-weight: 400;
        letter-spacing: 0.48px;
        border-radius: 2.5rem;
        border: 0.1rem solid $borderColor4;
        background: $white;
        @media (max-width: 767px) {
          padding: 1rem 2rem;
          font-size: 1.3rem;
        }

        &:focus,
        &:focus-visible {
          border: 0.1rem solid $orange;
          outline: 0;
        }

        &::placeholder {
          color: #b0b0b0;
        }
      }
    }
    .commonBtn {
      height: 5rem;
      min-width: 23.3rem;
      padding: 1rem;
      text-align: center;
      margin-top: 4.8rem;
      @media (max-width: 767px) {
        height: 4rem;
        margin-top: 2rem;
        min-width: 16rem;
        padding: 2rem;
      }
    }
  }
}
