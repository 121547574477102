@import "../../../../assets/theme/_var.scss";

section.FutureFinance {
  padding: 5rem 0;

  @media (max-width: 991px) {
    padding: 5rem 0;
  }

  @media (max-width: 575px) {
    padding: 1rem 0;
  }

  .Finance_wrap {
    border-radius: 1.2rem;
    background: linear-gradient(270deg, #FFCA63 1.74%, #C0558C 97.68%);
    text-align: center;
    padding: 8rem 2rem;
    color: $white;

    @media (max-width: 1199px) {
      padding: 5rem 2rem;
    }

    @media (max-width: 991px) {
      padding: 4rem;
    }

    @media (max-width: 575px) {
      padding: 2rem 1rem;
    }

    img {
      border: 5rem;

      @media (max-width: 991px) {
        width: 18rem;
      }

      @media (max-width: 575px) {
        width: 12rem;
      }
    }

    h3 {
      font-weight: 500;
      margin: 3rem 0 1.8rem;
      color: $white;

      @media (max-width: 991px) {
        margin: 2.4rem 0;
      }

      @media (max-width: 575px) {
        margin: 1.5rem 0 1rem;
        font-size: 1.3rem;
      }
    }

    h2 {
      margin: 0;
      font-weight: 600;
      color: $white;
    }
  }
}